
import axios from "axios";
import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import {
  getOneFunding,
  Funding,
  getAccount,
  getMyFundingAmount,
  contribute,
  returnMoney,
  newProve,
  getProve,
  addListener,
  returnDetail,
} from "../api/contract";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import Modal from "../components/base/modal.vue";
import CreateForm from "../components/base/createForm.vue";
import Use from "../components/Use.vue";
import { Model, Fields, Form } from "../type/form";

const column = [
  {
    dataIndex: "",
  },
];

const tabList = [
  {
    key: "info",
    tab: "项目介绍",
  },
  {
    key: "use",
    tab: "使用请求",
  },
];

export default defineComponent({
  name: "Funding",
  components: {
    Modal,
    CreateForm,
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
    Use,
  },
  setup() {
    onMounted(() => {
      returnDetails();
      returnProve();
    });
 
    let control:any=ref([])
    // =========基本数据==========
    const route = useRoute();
    const id = parseInt(route.params.id as string);
    const account = ref("");
    const state = reactive<{
      data: Funding | {};
      loading: boolean;
      myAmount: number;
    }>({
      data: {},
      loading: true,
      myAmount: 0,
    });
    const details = ref([]);

    //弹出框
    const visible = ref<boolean>(false);
    const showModal = () => {
      visible.value = true;
    };
    let loading = ref(false);
    const formState = reactive({
      info: "",
      relation: "",
      img: "",
    });
    const formRef = ref();
    const handleOk = async (e: MouseEvent) => {
      formRef.value
        .validate()
        .then(async () => {
          try {
            Loading.value = true;
            loading.value = true;
            const res = await newProve(
              id,
              formState.info,
              formState.relation,
              String(url.value)
            );

            message.success("证实成功");
            Loading.value = false;
            loading.value = false;
            visible.value = false;
      returnProve();
            formRef.value.resetFields();
            url.value = "";
          } catch (e) {
            console.log(e);
            message.error("证实失败");
          }
        })
        .catch(() => {
          console.log("error");
          message.error("请将信息填写完整");
        });
    };

    //---------------------------------------帮助证实-------------------------------
    const activeKey = ref(['1']);
    const text = ` found as a welcome guest in many households across the world.`;
    const customStyle =
      'background:#1890ff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;';
    const rules = {
      info: [{ required: true, message: "证实内容不能为空", trigger: "blur" }],
      img: [{ required: true, message: "证实图片不能为空", trigger: "blur" }],
      relation: [
        { required: true, message: "证实关系不能为空", trigger: "blur" },
        {
          min: 2,
          max: 5,
          message: "证实关系应该在 2 到 5字 之间",
          trigger: "blur",
        },
      ],
    };
    let urlContext = ref("");
    let url = ref("");
    //帮助证实（上传图片）

    const upload = async (e: any) => {
      var coverImage = e.file;
      var data = new FormData();
      data.append("arg", coverImage);
      var config = {
        method: "post",
        url:
          "https://ipfscc.bsngate.com:18602/ipfs/peer1/90e7dec9503c4696bc0def7ae820b523/api/v0/add?pin=true",
        // url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        // headers: {
        //   pinata_api_key: "cb75ec5381d05d8a3fe9",
        //   pinata_secret_api_key:
        //     "7718a452b603e3f685a137cef876d3064f61ae6c54707fb3d6e60059b9bd93c2",
        //   "Content-Type": `multipart/form-data`,
        // },
        data: data,
      };

      const res = await axios(config);

      urlContext.value = String(res.data.Hash);

      url.value =
        "https://ipfscc.bsngate.com:18602/ipfs/peer1/0e109dc62b50452fa9a4bc58b94c1a82/api/v0/cat?arg=" +
        String(urlContext.value);

      console.log(url.value);
      formState.img = url.value;
    };
    const close = () => {
      formRef.value.resetFields();
      url.value = "";
    };
    let Loading = ref(false);

    // ===========发起捐助表单============
    const isOpen = ref(false);
    function openModal() {
      isOpen.value = true;
    }
    function closeModal() {
      isOpen.value = false;
    }

    const model = reactive<Model>({
      value: 1,
    });
    const fields = reactive<Fields>({
      value: {
        type: "number",
        min: 0.00000000000001,
        label: "捐助金额",
      },
    });
    const form = reactive<Form>({
      submitHint: "捐助",
      cancelHint: "取消",
      cancel: () => {
        closeModal();
      },
      finish: async () => {
        try {
          await contribute(id, model.value);
          message.success("捐助成功");
          fetchData();
          closeModal();
        } catch (e) {
          message.error("捐助失败");
        }
      },
    });

    async function returnM() {
      try {
        await returnMoney(id);
        message.success("退钱成功");
        fetchData();
      } catch (e) {
        message.error("退钱失败");
      }
    }
    //============获取详情数据=========
    async function returnDetails() {
      try {
        var res = await returnDetail(id);
        console.log(res);
        details.value = res;
      } catch (e) {}
    }
    //============获取证实数据=========
    let proveList=ref([] as any[])
    async function returnProve() {
      try {
        var res = await getProve(id);
        console.log(res);
        proveList.value=res
        control.value=[1]
        console.log(control.value.length);
        
      } catch (e) {}
    }
    // =========切换标签页===========
    const key = ref("info");
    const onTabChange = (k: "use" | "info") => {
      key.value = k;
    };

    // =========加载数据===========
    async function fetchData() {
      state.loading = true;
      try {
        [state.data, state.myAmount] = await Promise.all([
          getOneFunding(id),
          getMyFundingAmount(id),
        ]);
        state.loading = false;
        //@ts-ignore
        fields.value.max = state.data.goal - state.data.amount;
      } catch (e) {
        console.log(e);
        message.error("获取详情失败");
      }
    }

    const dataSource = ref([]);

    addListener(fetchData);

    getAccount().then((res) => (account.value = res));
    fetchData();

    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      formState,
      state,
      account,
      isOpen,
      openModal,
      form,
      model,
      fields,
      tabList,
      key,
      details,
      onTabChange,
      id,
      returnM,
      returnDetails,
      visible,
      showModal,
      handleOk,
      url,
      upload,
      rules,
      formRef,
      close,
      Loading,
       activeKey,
      text,
      customStyle,
      proveList,
      control
    };
  },
});
